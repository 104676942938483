<template>
  <div class="personal-info-tab">
    <div class="content">
      <div class="double-row">
        <text-input
          class="first-name"
          label="First name"
          v-model="firstName"
        ></text-input>
        <text-input
          class="last-name"
          label="Last name"
          v-model="lastName"
        ></text-input>
      </div>
      <div class="single-row">
        <text-input class="email" label="Email" v-model="email"></text-input>
      </div>
      <div class="double-row last">
        <text-input
          class="password"
          label="New password"
          v-model="password"
          type="password"
        ></text-input>
        <text-input
          class="confirm-password"
          label="Confirm password"
          v-model="confirmPassword"
          type="password"
        ></text-input>
      </div>
      <div class="info-row">
        <p
          class="password-invalid"
          v-show="password.length > 0 && password.length < 6"
        >
          Password must be at least 6 characters
        </p>
      </div>
      <div style="clear: both"></div>

      <div class="button-row">
        <primary-button
          label="Save"
          class="profile-submit"
          v-bind:clicked="confirm"
          v-bind:disabled="formDisabled"
        ></primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '../components/TextInput'
import PrimaryButton from '../components/PrimaryButton'
import userEditIcon from '@/assets/user-bg.svg'

export default {
  name: 'PersonalInfoTab',
  components: {
    TextInput,
    PrimaryButton
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      userId: ''
    }
  },
  created() {
    this.delayedRefresh()

    this.$events.$on('admin_updated', (data) => {
      this.updateUserData(data)
    })
  },
  methods: {
    updateUserData: async function (data) {
      this.firstName = data.user.firstName
      this.lastName = data.user.lastName
      this.email = data.user.email
      this.userId = data.user.id
      this.$events.$emit('hideLoading')
    },
    delayedRefresh: function () {
      this.$events.$emit('showLoading')
      setTimeout(() => {
        let userData = this.$session.getUserData()
        this.updateUserData(userData)
      }, 500)
    },
    confirm: async function () {
      try {
        this.$events.$emit('showLoading')

        let user = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }

        if (this.password.length > 0) {
          user.password = this.password
        }

        await this.$userStore.updateUserById(this.userId, user)
        this.$session.updateUser(this.firstName, this.lastName, this.email)

        this.$notify({
          group: 'admin-actions',
          title: 'Updated Account',
          text: `${user.firstName} ${user.lastName}’s information was successfully updated`,
          data: {
            iconPath: userEditIcon
          }
        })

        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('error', err)
      }
    }
  },
  computed: {
    formDisabled() {
      if (!this.firstName || !this.lastName || !this.email) {
        return true
      }

      // Validate any provided password
      if (this.password || this.confirmPassword) {
        if (
          this.password.length < 6 ||
          this.password !== this.confirmPassword
        ) {
          return true
        }
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.personal-info-tab {
  margin-top: -23px;

  .content {
    width: 506px;
    height: 419px;

    .double-row {
      width: 506px;
      height: 50px;
      margin-bottom: 32px;

      .text-input {
        width: 223px;
        float: left;
      }
      .text-input:first-child {
        margin-left: 19px;
        margin-right: 26px;
      }
      .text-input:last-child {
        width: 218px;
      }
    }

    .double-row:nth-child(2) {
      margin-bottom: 24px;
    }

    .double-row.last {
      margin-bottom: 10px;
    }

    .single-row {
      height: 50px;
      margin-bottom: 31px;

      .text-input {
        width: 467px;
        margin-left: 19px;
      }
    }

    .single-row.results {
      margin-bottom: 28px;
    }

    .info-row {
      min-height: 36px;
      margin-bottom: 22px;
      padding-left: 19px;
      padding-top: 10px;
      text-align: left;
      font-family: Barlow;
      font-size: 12px;
    }

    .button-row {
      width: 506px;
      text-align: center;

      .primary-button {
        width: 190px;
        height: 60px;
      }
    }
  }
}
</style>
