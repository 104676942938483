<template>
  <div id="organization" class="org-management-page">
    <OrgTabs
      v-bind:tabs="tabs"
      :companyId="companyId"
      v-bind:initialTab="$route.params.type"
    />
  </div>
</template>
<script>
import OrgTabs from '@/components/OrgTabs.vue'
import CompanyIcon from '@/assets/company.svg'
import userAddIcon from '@/assets/user-outline.svg'

export default {
  components: {
    OrgTabs
  },
  data() {
    let tabs = [
      {
        title: 'Users',
        component: 'memberTab',
        path: 'members'
      },
      {
        title: 'Trucks',
        component: 'truckTab',
        path: 'trucks'
      },
      {
        title: 'Trailers',
        component: 'trailerTab',
        path: 'trailers'
      }
    ]

    return {
      tabs,
      companyId: this.$route.params.companyId
    }
  },
  created() {
    // Make sure the session is updated to track which page we're viewing
    this.$events.$on('admin_updated', (data) => {
      if (!data.currentCompany && !data.isSuperAdmin) {
        this.$session.updateCurrentCompany(this.companyId)
      }
    })
  },
  methods: {
    addNew: function () {
      switch (this.$route.params.type) {
        case 'user':
          this.$events.$emit('showUserModal', {
            title: 'New User',
            user: {},
            callback: async (confirmed, data) => {
              try {
                if (!confirmed) return
                this.$events.$emit('showLoading')
                let companyId = data.company.selected.id
                if (companyId === 'new_company') {
                  companyId = await this.$companyStore.add(
                    data.company.selected.name
                  )
                  if (companyId) {
                    this.$notify({
                      group: 'admin-actions',
                      title: 'Created Company',
                      text: `${data.company.selected.name} was successfully created`,
                      data: {
                        iconPath: CompanyIcon
                      }
                    })
                  }
                }

                let user = {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  email: data.email,
                  phoneNumber: data.phoneNumber,
                  companyId: companyId,
                  role: data.role.selected.title,
                  password: data.password
                }

                let userSuccess = await this.$userStore.add(user)
                if (userSuccess) {
                  this.$notify({
                    group: 'admin-actions',
                    title: 'Created Account',
                    text: `${data.firstName} ${data.lastName} was successfully created`,
                    data: {
                      iconPath: userAddIcon
                    }
                  })
                }
              } catch (err) {
                return err // Handled by user modal
              } finally {
                this.$events.$emit('hideLoading')
              }
            }
          })
          break
        case 'company':
          this.$events.$emit('showBasicModal', {
            title: 'New Company',
            mode: 'input',
            confirmText: 'Save',
            callback: async (confirmed, data) => {
              if (!confirmed) return
              this.$events.$emit('showLoading')

              let companySuccess
              try {
                companySuccess = await this.$companyStore.add(data.inputValue)
              } catch (err) {
                this.$events.$emit('error', err)
                companySuccess = false
              } finally {
                this.$events.$emit('hideLoading')

                if (companySuccess) {
                  this.$notify({
                    group: 'admin-actions',
                    title: 'Created Company',
                    text: `${data.inputValue} was successfully created`,
                    data: {
                      iconPath: CompanyIcon
                    }
                  })
                }
              }
            }
          })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.org-management-page {
  #add-new {
    margin: 0px;
    width: 140px;
    z-index: 2;

    img {
      vertical-align: middle;
    }
  }
}
</style>
