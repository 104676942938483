<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div id="table-box">
    <div class="search">
      <search-input
        v-model="search"
        v-bind:submitted="searchSubmitted"
        v-bind:returnValue="truckDocumentSearch"
        :suggestions="suggestions"
        v-bind:returnSuggestion="suggestion"
      ></search-input>

      <div class="search--filters">
        <multiselect
          class="multi-select filter-format-multi-select"
          label="title"
          track-by="id"
          v-model="state.selected"
          selectLabel
          deselectLabel
          selectedLabel
          :options="state.options"
          :option-width="80"
          :option-height="20"
          :searchable="false"
          :allowEmpty="true"
          open-direction="bottom"
          @input="setFilterState"
        >
          <template v-slot:singleLabel="props">
            <span class="option__title">{{ props.option.title }}</span>
            <img src="../assets/multiselect-arrow.png" class="arrow" />
          </template>
          <template v-slot:option="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.title }}</span>
            </div>
          </template>
        </multiselect>

        <multiselect
          class="multi-select filter-format-multi-select"
          label="title"
          track-by="id"
          v-model="format.selected"
          selectLabel
          deselectLabel
          selectedLabel
          :options="format.options"
          :option-width="80"
          :option-height="20"
          :searchable="false"
          :allowEmpty="true"
          open-direction="bottom"
          @input="formatFilterChanged"
        >
          <template v-slot:singleLabel="props">
            <span class="option__title">{{ props.option.title }}</span>
            <img src="../assets/multiselect-arrow.png" class="arrow" />
          </template>
          <template v-slot:option="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.title }}</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="search--buttons">
        <button
          class="button button-primary"
          id="add-new"
          v-on:click="adding = true"
          @click="addDocument"
        >
          <img src="@/assets/plus-circle.svg" alt="plus sign in circle" />
          Add
        </button>
      </div>
    </div>
    <div id="table-controls">
      <div
        class="arrow"
        v-bind:class="computedSortClass()"
        v-on:click="setSortBy()"
      ></div>
    </div>
    <table id="table" class="document-table">
      <thead id="document-header">
        <tr id="table-header">
          <th title="icon"></th>
          <th
            v-for="column in columns"
            v-bind:key="column.id"
            class="noselect"
            v-bind:class="column.id"
          >
            <span class="column-title" v-on:click="setSortBy(column.id)">
              {{ column.title }}
            </span>
            <div
              class="arrow"
              v-bind:class="computedSortClass(column.id)"
              v-on:click="setSortBy(column.id)"
            ></div>
          </th>
          <th title="menu"></th>
        </tr>
      </thead>
      <tbody id="document-body">
        <tr
          class="table-row"
          v-for="(document, index) in paginatedData"
          v-bind:key="document.id"
          v-bind:class="{
            expired: isExpired(document.expiration),
            expiring: isExpiring(document.expiration)
          }"
          v-if="filterState(document.expiration)"
        >
          <td>
            <document-icon
              :key="index"
              v-bind:format="getDocFormat(document.url)"
            />
          </td>
          <td>
            {{ document.docName }}
            {{ isExpired(document.expiration) ? '(Expired)' : '' }}
          </td>
          <td>{{ document.assignedTo }}</td>
          <td>{{ document.versionDateFormatted }}</td>
          <td>
            <a
              class="status status--expired"
              title="Expired"
              v-if="isExpired(document.expiration)"
            ></a>
            <a
              class="status status--expiring"
              title="Expiring Soon"
              v-if="isExpiring(document.expiration)"
            ></a>
            <a
              class="status status--current"
              title="Current"
              v-if="
                !isExpiring(document.expiration) &&
                !isExpired(document.expiration)
              "
            ></a>
          </td>
          <td>{{ document.lastUpdatedFormatted }}</td>
          <td>{{ document.docFormat }}</td>
          <td
            v-on:click.prevent="$refs[`itemMenu${index}`][0].changeMenuStatus()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="6"
              viewBox="0 0 26 6"
            >
              <g fill="#124E81" fill-rule="evenodd">
                <circle cx="3" cy="3" r="3" />
                <circle cx="13" cy="3" r="3" />
                <circle cx="23" cy="3" r="3" />
              </g>
            </svg>
            <dropdown
              :key="index"
              :ref="`itemMenu${index}`"
              top="50px"
              left="-133px"
            >
              <p class="dropdown-item" v-on:click="viewDocument(document)">
                View Document
              </p>
              <p class="dropdown-item" v-on:click="editDocument(document)">
                Edit Document
              </p>
              <p class="dropdown-item" v-on:click="deleteDocument(document)">
                Delete Document
              </p>
            </dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <PaginationControls
      v-on:changePage="pageChange"
      :pageNumber="this.pagination.pageNumber"
      :pageMax="this.maxPage"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-use-v-if-with-v-for */
import PaginationControls from '@/components/PaginationControls.vue'
import SearchInput from '@/components/SearchInput.vue'
import Dropdown from '@/components/DropDownMenu.vue'
import DocumentIcon from '@/components/DocumentIcon.vue'
import DocumentSvg from '@/assets/dark-document.svg'
import TrashIcon from '@/assets/trash.svg'
import Multiselect from 'vue-multiselect'
import { status, equipmentColumns, filterState } from '../utils/documents.js'

export default {
  components: {
    PaginationControls,
    SearchInput,
    Dropdown,
    DocumentIcon,
    Multiselect
  },
  data() {
    return {
      columns: equipmentColumns,
      sortBy: 'docName',
      sortDirection: 'up',
      ownerId: null,
      docType: 'truck',
      allTruckDocs: [],
      pagination: {
        pageLimit: 7,
        pageNumber: 1
      },
      totalCount: 0,
      search: '',
      suggestions: [],
      state: status,
      format: {
        selected: {
          title: 'Format',
          id: null
        },
        options: [
          {
            title: 'PDF',
            id: 'pdf'
          },
          {
            title: 'PNG',
            id: 'png'
          }
        ]
      }
    }
  },
  created() {
    this.refreshList()
    this.$events.$on('truck_documents_refresh', (data) => {
      this.refreshList(data)
    })
  },
  props: ['companyId'],
  computed: {
    ownerType: function () {
      switch (this.docType) {
        case 'driver':
        case 'truck':
        case 'trailer':
          return 'user'
        default:
          return 'company'
      }
    },
    maxPage: function () {
      return Math.ceil(this.totalCount / this.pagination.pageLimit)
    },
    computedSortClass: function () {
      return function (sortId) {
        if (this.sortBy === sortId) {
          return 'sorting ' + this.sortDirection
        } else {
          return ''
        }
      }
    },
    paginatedData: function () {
      let newData = []
      let startItem =
        this.pagination.pageLimit * (this.pagination.pageNumber - 1)
      let endItem = startItem + this.pagination.pageLimit
      // Sort
      this.$util.sortColumns(this.allTruckDocs, this.sortBy, this.sortDirection)
      // Return list
      for (startItem; startItem < endItem; startItem++) {
        if (this.allTruckDocs[startItem]) {
          newData.push(this.allTruckDocs[startItem])
        }
      }
      return newData
    }
  },
  methods: {
    isExpiring: function (date) {
      return this.$util.isExpiring(date)
    },
    isExpired: function (date) {
      return this.$util.isExpired(date)
    },
    setFilterState: function (state) {
      if (!state) {
        this.state.selected = {
          title: 'Expiration',
          id: null
        }
        return
      }
      this.state.selected = state
    },
    filterState: function (date) {
      return filterState(this.state.selected.id, date, this)
    },
    addDocument: function () {
      this.$events.$emit('showUploadModal', {
        title: 'Edit Truck Document',
        type: 'Truck',
        callback: async (confirmed, data) => {
          if (!confirmed) return
          try {
            this.$events.$emit('showLoading')
            await this.$docStore.add(
              data.uploadedFileUrl,
              data.type.toLowerCase(),
              data.inputValue,
              data.owner.selected ? data.owner.selected.id : null,
              this.companyId,
              data.dateFormatted
            )
            this.$notify({
              group: 'admin-actions',
              title: `Document Created`,
              text: `${data.inputValue} was successfully created.`,
              data: {
                iconPath: DocumentSvg
              }
            })
            this.$events.$emit('hideLoading')
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      })
    },
    editDocument: function (document) {
      this.$events.$emit('showUploadModal', {
        title: 'Edit Truck Document',
        ownerId: document.ownerId,
        type: 'Truck',
        defaultValue: document.docName,
        uploadedFileUrl: document.url,
        expiration: document.expiration,
        callback: async (confirmed, data) => {
          if (!confirmed) return
          try {
            this.$events.$emit('showLoading')
            await this.$docStore.edit(
              document.id,
              data.uploadedFileUrl,
              data.type.toLowerCase(),
              data.inputValue,
              data.owner.selected ? data.owner.selected.id : null,
              this.companyId,
              data.dateFormatted
            )
            this.$notify({
              group: 'admin-actions',
              title: 'Updated Document',
              text: `${data.inputValue} was successfully updated`,
              data: {
                iconPath: DocumentSvg
              }
            })
            this.$events.$emit('hideLoading')
          } catch (err) {
            this.$events.$emit('error', err)
          }
        }
      })
    },
    setSortBy: function (sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === 'up') {
          this.sortDirection = 'down'
        } else {
          this.sortDirection = 'up'
        }
      } else {
        this.sortDirection = 'up'
        this.sortBy = sortBy
      }
    },
    searchSubmitted: function () {
      this.refreshList()
    },
    getDocFormat: function (url) {
      return url.slice(-3)
    },
    truckDocumentSearch: async function (val) {
      try {
        this.sortBy = 'docName'
        if (val === '') {
          this.suggestions = []
          this.refreshList()
          return
        }
        const documents = await this.$docStore.loadDocs(
          null,
          this.docType,
          this.companyId,
          this.format.selected.id,
          val,
          true
        )
        if (documents.length === 0) {
          this.suggestions = [
            {
              id: 'none',
              name: 'No Results Found',
              points: 9999
            }
          ]
          return
        }
        this.suggestions = documents
      } catch (err) {
        console.log(err)
        this.$events.$emit('hideLoading')
      }
    },
    suggestion: function (suggestion) {
      if (suggestion.id === 'none') return
      this.suggestions = []
      this.search = suggestion.name
      this.allTruckDocs = [suggestion]
    },
    refreshList: async function (data) {
      try {
        this.$events.$emit('showLoading')
        if (data) {
          let count = data.get(this.docType).totalDocs
          this.allTruckDocs = data.get(this.docType).docs
          // Generate doc format in advance to allow for sorting
          for (let i = 0; i < this.allTruckDocs.length; i++) {
            this.allTruckDocs[i].docFormat = this.getDocFormat(
              this.allTruckDocs[i].url
            )
            this.allTruckDocs[i].versionDateFormatted = this.getFormattedDate(
              this.allTruckDocs[i].versionDate
            )
            this.allTruckDocs[i].lastUpdatedFormatted = this.getFormattedDate(
              this.allTruckDocs[i].lastUpdated
            )

            this.allTruckDocs[i].status = 'none'
            if (this.isExpiring(this.allTruckDocs[i].expiration)) {
              this.allTruckDocs[i].status = 'expiring'
            }

            if (this.isExpired(this.allTruckDocs[i].expiration)) {
              this.allTruckDocs[i].status = 'expired'
            }
          }
          this.totalCount = count < 1 ? 1 : count
        } else {
          await this.$docStore.loadDocs(
            null,
            this.docType,
            this.companyId,
            this.format.selected.id,
            this.search
          )
          this.pagination.pageNumber = 1
        }
        setTimeout(() => {
          this.$events.$emit('hideLoading')
        }, 500)
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    getFormattedDate: function (timeStamp) {
      return this.$moment(timeStamp).format('MM/DD/YYYY')
    },
    pageChange: function (newPage) {
      this.pagination.pageNumber = newPage
    },
    deleteDocument: function (doc) {
      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: 'Delete Confirmation',
        text: `Are you sure you want to delete ${doc.docName}?`,
        secondaryText: 'This action cannot be undone',
        callback: async (resp) => {
          if (resp) {
            this.$events.$emit('showLoading')
            let docDelSuccess = true
            try {
              await this.$docStore.delete(doc, this.docType)
            } catch (err) {
              this.$events.$emit('error', err)
            } finally {
              this.$events.$emit('hideLoading')
              if (docDelSuccess) {
                this.$notify({
                  group: 'admin-actions',
                  title: 'Deleted Document',
                  text: `${doc.docName} was successfully deleted`,
                  data: {
                    iconPath: TrashIcon
                  }
                })
              }
            }
          }
        },
        cancelText: 'Cancel',
        confirmText: 'Delete'
      })
    },
    formatFilterChanged: function (newVal) {
      if (!newVal) {
        this.format.selected = {
          title: 'Format',
          id: null
        }
      }
      this.refreshList()
    },
    viewDocument: function (document) {
      if (document.url) {
        window.open(document.url)
      }
    }
  }
}
</script>

<style lang="scss"></style>
