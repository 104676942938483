<template>
  <div class="document-icon">
    <img
      v-if="format.toLowerCase() === 'pdf'"
      src="@/assets/pdf.svg"
      alt="pdf icon"
    />
    <img v-else src="@/assets/png.svg" alt="png icon" />
  </div>
</template>

<script>
export default {
  name: 'icon',
  props: {
    format: {
      type: String,
      reqired: true,
      default: 'png'
    }
  }
}
</script>

<style lang="scss">
.document-icon img {
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
