<template>
  <div class="org-content">
    <router-link
      id="go-back"
      v-show="!loggedOut && isSuperAdmin"
      :to="getParentPage"
    >
      <img src="../assets/back.png" class="arrow" />
      <span>Back</span>
    </router-link>
    <div id="dynamic-tabs">
      <button
        v-for="tab in tabs"
        v-bind:key="tab.component"
        v-bind:class="['tab-button', { active: currentTabFunc(tab) }]"
        v-on:click="updateTab(tab)"
      >
        {{ tab.title }}
      </button>
    </div>

    <keep-alive>
      <component
        :key="tabKey"
        :companyId="companyId"
        :is="currentTabComponent"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import MemberTab from '@/components/MemberTab.vue'
import TruckTab from '@/components/TruckTab.vue'
import TrailerTab from '@/components/TrailerTab.vue'
import CompanyDocumentsTab from '@/components/CompanyDocumentsTab'
import DriverDocumentsTab from '@/components/DriverDocumentsTab.vue'
import TrailerDocumentsTab from '@/components/TrailerDocumentsTab.vue'
import TruckDocumentsTab from '@/components/TruckDocumentsTab.vue'

export default {
  components: {
    MemberTab,
    TruckTab,
    TrailerTab,
    CompanyDocumentsTab,
    TruckDocumentsTab,
    TrailerDocumentsTab,
    DriverDocumentsTab
  },
  props: ['tabs', 'initialTab', 'tabType', 'companyId'],
  data() {
    return {
      currentTab: this.initialTab,
      loggedOut: false,
      isSuperAdmin: false
    }
  },
  created() {
    this.loggedOut = !this.$session.isSignedIn()

    let userData = this.$session.getUserData()
    this.refreshAdmin(userData)

    this.$events.$on('admin_updated', (data) => {
      this.refreshAdmin(data)
    })
  },
  watch: {
    $route(to) {
      if (
        to.path.indexOf('management') > -1 ||
        to.path.indexOf('documents') > -1
      ) {
        let pathParts = to.path.split('/')
        let subPath = pathParts[pathParts.length - 1]
        for (let i = 0; i < this.tabs.length; i++) {
          if (subPath === this.tabs[i].path) {
            this.currentTab = this.tabs[i].path
            break
          }
        }
      }
    }
  },
  computed: {
    getParentPage: function () {
      if (this.$route.fullPath.toLowerCase().indexOf('company') > -1) {
        return {
          title: 'Back',
          path: '/admin/company'
        }
      } else {
        return {
          title: 'Back',
          path: '/admin/user'
        }
      }
    },
    currentTabComponent: function () {
      let path = this.currentTab.toLowerCase()

      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].path === path) {
          return this.tabs[i].component
        }
      }

      return ''
    },
    tabKey: function () {
      return this.$route.params.type
    }
  },
  methods: {
    refreshAdmin: function (userData) {
      if (userData) {
        this.isSuperAdmin = userData.isSuperAdmin
      }
    },
    updateTab: function (tab) {
      this.currentTab = tab.path
      if (window.location.href.indexOf('management') > -1) {
        this.$router.push(`/org/${this.companyId}/management/${tab.path}`)
      } else {
        this.$router.push(`/org/${this.companyId}/documents/${tab.path}`)
      }
    },
    currentTabFunc: function (tab) {
      return this.currentTab === tab.path
    }
  }
}
</script>

<style lang="scss">
@import '../scss/_table.scss';

.org-content {
  min-width: 800px;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

#go-back {
  position: absolute;
  left: 0px;
  top: -60px;
  cursor: pointer;

  .arrow {
    margin-right: 8px;
    margin-bottom: -2px;
  }

  span {
    font-size: 18px;
    color: #505050;
  }
}

#go-back:hover {
  text-decoration: underline;
}

#dynamic-tabs {
  min-height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tab-button {
    margin: 0px 16px 24px;
    padding: 0px;
    border: none;
    cursor: pointer;
    background: $white;
    color: $cool-grey-large;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    outline: none;
    justify-self: center;
  }
  .tab-button.active {
    border-bottom: 2px solid $light-navy;
    color: $light-navy;
  }
  .tab {
    border: 1px solid #ccc;
    padding: 10px;
  }
}

#table-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead th {
    height: 46px;
    text-shadow: 1px 1px 0 $black-trans;
    font-family: Barlow;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: $white;
    background-color: $light-navy;
  }

  th,
  td {
    padding-left: 16px;
    border-bottom: 1px solid $warm-grey;
    vertical-align: middle;
    text-align: left;
  }

  tbody {
    font-family: Barlow;
    font-size: 18px;
    line-height: 30px;
    color: $greyish-brown;

    tr:nth-child(odd) {
      background: $pale-blue;
    }

    td {
      height: 62px;
      word-wrap: break-word;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
    }

    td:last-child {
      cursor: pointer;
      &:hover {
        * {
          fill: $mid-blue;
        }
      }
    }
  }
}
</style>
