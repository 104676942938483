<template>
  <div class="billing-tab">
    <div class="billing-section drivers-section">
      <p class="section-label">Number of Drivers in your Company</p>
      <p class="section-value">
        <span class="num-drivers">{{ numDrivers }}</span>
      </p>
      <div style="clear: both"></div>
    </div>
    <div class="billing-section next-invoice-section">
      <div class="section-label">
        Total Billed on your Next Invoice
        <div class="help" v-on:click="showSubscriptionMatrix()"></div>
      </div>
      <p class="section-value">
        <sup class="dollar">$</sup>
        <span class="next-bill">{{ nextBill }}</span>
        <sup class="asterisk">*</sup>
        <span class="month">/mo</span>
      </p>
      <div style="clear: both"></div>
      <p class="disclaimer">
        * Your invoice total varies based on the number of drivers
      </p>
    </div>
    <div
      class="billing-section card-section"
      v-if="subscription && !subscription.cancelled"
    >
      <p class="section-label">Payment Information</p>
      <div style="clear: both"></div>
      <p class="card" v-show="last4">
        **** **** ****
        <span class="last4">{{ last4 }}</span>
      </p>
      <p class="section-action" v-on:click="changeCard()">Change Card</p>
      <div style="clear: both"></div>
      <p class="expired" v-show="subscription.status === 'inactive'">
        Your account payment is past due, please update your information.
      </p>
    </div>
    <div
      class="billing-section invoice-section"
      v-if="subscription && latestInvoices.length > 0"
    >
      <p class="section-label">Billing History</p>
      <p
        class="section-action"
        v-show="hasMoreInvoices"
        v-on:click="showMore()"
      >
        Show More
      </p>
      <div style="clear: both"></div>
      <table>
        <tbody>
          <tr v-for="(invoice, index) in latestInvoices" :key="index">
            <td class="date">
              {{ getFormattedDate(invoice.created || invoice.date) }}
            </td>
            <td class="amount">$ {{ getFormattedCurrency(invoice) }}</td>
            <td class="icon">
              <a :href="invoice.invoice_pdf || invoice.pdfUrl" target="_blank">
                <div></div>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingTab',
  components: {},
  data() {
    return {
      numDrivers: 0,
      nextBill: 0,
      subscription: null,
      last4: null,
      latestInvoices: [],
      hasMoreInvoices: false
    }
  },
  created() {
    this.$events.$on('subscription_data', (data) => {
      try {
        this.numDrivers = data.numDrivers
        this.subscription = data.subscription
        this.nextBill = this.getFormattedCurrency(null, data.nextBill)
        this.last4 = data.last4
        this.latestInvoices = data.latestInvoices
        this.hasMoreInvoices = data.hasMoreInvoices
        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('error', err)
      }
    })

    this.refreshData()
  },
  methods: {
    async refreshData() {
      let loadSubscription = async () => {
        this.$events.$emit('showLoading')
        try {
          let sessionData = this.$session.getUserData()
          if (!sessionData.user.id) {
            setTimeout(() => {
              loadSubscription()
            }, 500)
            return
          }
          await this.$subscriptionStore.getTopLevelData(sessionData.user.id)
        } catch (err) {
          this.$events.$emit('error', err)
        }
      }

      loadSubscription()
    },
    async changeCard() {
      this.$events.$emit('showPaymentModal', {
        mode: 'update_card',
        callback: (completed) => {
          if (completed) {
            this.refreshData()
          }
        }
      })
    },
    async showMore() {
      try {
        this.$events.$emit('showLoading')
        this.latestInvoices = await this.$subscriptionStore.loadAllInvoices()
        this.hasMoreInvoices = false
        this.$events.$emit('hideLoading')
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    showSubscriptionMatrix() {
      this.$events.$emit('showSubscriptionMatrixModal', { mode: 'pricing' })
    }
  },
  computed: {
    getFormattedDate: function () {
      return function (timeStamp) {
        return this.$moment.unix(timeStamp).format('MMMM Do, YYYY')
      }
    },
    getFormattedCurrency: function () {
      return function (invoice, amount) {
        if (invoice) {
          amount = invoice.amount_due
          if (!amount && amount !== 0) {
            amount = invoice.due
          }
        }
        amount = parseFloat(amount / 100).toFixed(2)
        if (amount % 1 === 0) {
          amount = parseInt(amount)
        }
        return amount
      }
    }
  }
}
</script>

<style lang="scss">
.billing-tab {
  .billing-section {
    width: 506px;
    margin-bottom: 27px;
    padding-bottom: 27px;
    border-bottom: 1px solid #aac7df;

    .section-label {
      font-family: Barlow;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #505050;
    }

    .section-action {
      float: right;
      font-family: Barlow;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #124e81;
      cursor: pointer;
    }
  }

  .billing-section:last-child {
    border-bottom: 0;
  }

  .billing-section {
    .section-label {
      position: relative;
      float: left;
    }

    p.section-value {
      float: right;
    }
  }

  .billing-section.next-invoice-section {
    p.section-value {
      .dollar,
      .asterisk {
        font-family: Barlow;
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #505050;
        vertical-align: super;
      }

      .month {
        font-family: Barlow;
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #505050;
      }
    }
    .help {
      position: absolute;
      top: 2px;
      right: -24px;
      width: 16px;
      height: 16px;
      background-image: url(../assets/help.png);
      background-position: 0 0;
      background-size: 32px 16px;
      cursor: pointer;
    }
    .help:hover {
      background-position: -16px 0;
    }
    p.disclaimer {
      font-family: Barlow;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #505050;
    }
  }

  .billing-section.card-section {
    p.card {
      float: left;
      margin-top: 8px;
      font-family: Barlow;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #505050;
    }
    p.expired {
      font-family: Barlow;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: red;
    }
  }

  .billing-section.invoice-section {
    table {
      margin-top: 18px;

      tr td {
        padding-bottom: 13px;
      }

      .date {
        width: 269px;
        font-family: Barlow;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #505050;
      }
      .amount {
        width: 212px;
        font-family: Barlow;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #afb3bf;
      }
      .icon {
        div {
          width: 18px;
          height: 21px;
          background-image: url(../assets/invoice.png);
          background-position: 0 0;
          background-size: 36px 21px;
        }
        div:hover {
          background-position: -18px 0;
        }
      }
    }
  }
}
</style>
