<template>
  <div id="pagination-controls">
    <svg
      v-on:click="onClickPevious"
      v-if="pageNumber > 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <path id="b" d="M8.414 0L0 8l8.414 8L10 14.492 3.173 8 10 1.508z" />
        <filter
          id="a"
          height="112.5%"
          x="-5%"
          y="-3.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="1"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <rect
          width="30"
          height="30"
          fill="#124E81"
          fill-rule="nonzero"
          rx="15"
        />
        <g transform="translate(9 7)">
          <use fill="#000" filter="url(#a)" xlink:href="#b" />
          <use fill="#FFF" xlink:href="#b" />
        </g>
      </g>
    </svg>
    <svg
      v-on:click="onClickPevious"
      v-if="pageNumber < 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <path id="b" d="M8.414 0L0 8l8.414 8L10 14.492 3.173 8 10 1.508z" />
        <filter
          id="a"
          height="112.5%"
          x="-5%"
          y="-3.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="1"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <rect width="30" height="30" fill="#FFF" fill-rule="nonzero" rx="15" />
      </g>
    </svg>
    <span id="pagination-readout">
      <p>Page</p>
      <p class="pagination-var">{{ this.pageNumber }}</p>
      <p>of</p>
      <p class="pagination-var">{{ this.pageMax }}</p>
    </span>
    <svg
      v-on:click="onClickNext"
      v-if="pageNumber < pageMax"
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-1"
          points="8.41352964 0 0 8 8.41352964 16 10 14.4915055 3.17294073 8 10 1.50849447"
        />
        <filter
          x="-15.0%"
          y="-3.1%"
          width="120.0%"
          height="112.5%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="-1"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
      </defs>
      <g
        id="Components"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Components:-Buttons"
          transform="translate(-1321.000000, -274.000000)"
        >
          <g id="Content" transform="translate(1075.000000, 163.000000)">
            <g id="Search-Fields">
              <g id="Default" transform="translate(0.000000, 74.000000)">
                <g id="Icons" transform="translate(0.000000, 37.000000)">
                  <g
                    id="Pager-/Arrow/Right/Neutral"
                    transform="translate(246.000000, 0.000000)"
                  >
                    <g id="forward">
                      <rect
                        id="Rectangle-Copy"
                        fill="#124E81"
                        fill-rule="nonzero"
                        x="0"
                        y="0"
                        width="30"
                        height="30"
                        rx="15"
                      />
                      <g
                        id="Icon-/Arrow/-Back-Arrow-/-Dark-Blue-/-Thick"
                        transform="translate(16.000000, 15.000000) scale(-1, 1) translate(-16.000000, -15.000000) translate(11.000000, 7.000000)"
                      >
                        <g
                          id="Icon-/Arrow/-Back-Arrow-/-Dark-Blue-/-Thick-Right"
                          transform="translate(5.000000, 8.000000) scale(-1, 1) translate(-5.000000, -8.000000) "
                        >
                          <g
                            id="Back"
                            transform="translate(5.000000, 8.000000) scale(-1, 1) translate(-5.000000, -8.000000) "
                          >
                            <use
                              fill="black"
                              fill-opacity="1"
                              filter="url(#filter-2)"
                              xlink:href="#path-1"
                            />
                            <use
                              fill="#FFFFFF"
                              fill-rule="evenodd"
                              xlink:href="#path-1"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      required: true
    },
    pageMax: {
      type: Number,
      required: true
    }
  },
  methods: {
    onClickNext: function () {
      this.$emit('changePage', this.pageNumber + 1)
    },
    onClickPevious: function () {
      if (this.pageNumber > 1) {
        this.$emit('changePage', this.pageNumber - 1)
      }
    }
  }
}
</script>

<style lang="scss">
#pagination-controls {
  display: flex;
  flex-direction: row;
  font-size: 0.75em;
  line-height: 1em;
  color: $light-navy;
  padding: 8px;
  margin: 40px 8px;

  #pagination-readout {
    margin: 0px 16px;
    height: 30px;
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $pale-blue;
    border-radius: 14px;
  }

  .pagination-var {
    font-weight: 500;
  }

  rect #Rectangle-Copy * :hover {
    fill: $mid-blue;
  }

  p {
    margin: 2px;
  }
}
</style>
