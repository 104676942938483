<template>
  <div id="account">
    <AccountTabs v-bind:tabs="tabs" v-bind:initialTab="$route.params.type" />
  </div>
</template>
<script>
import AccountTabs from '@/components/AccountTabs.vue'

export default {
  components: {
    AccountTabs
  },
  data() {
    return {
      tabs: [
        {
          title: 'Personal Info',
          component: 'personalInfoTab',
          path: 'info'
        },
        {
          title: 'Billing',
          component: 'billingTab',
          path: 'billing'
        }
      ]
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
#account {
  position: relative;
}
</style>
