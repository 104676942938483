<template>
  <div class="company-tab">
    <div id="table-box">
      <div class="search">
        <search-input
          v-model="search"
          v-bind:submitted="searchSubmitted"
          v-bind:returnValue="companySearch"
          :suggestions="suggestions"
          v-bind:returnSuggestion="suggestion"
        ></search-input>

        <div class="search--filters">
          <multiselect
            class="multi-select filter-status-multi-select"
            label="title"
            track-by="id"
            v-model="status.selected"
            selectLabel
            deselectLabel
            selectedLabel
            :options="status.options"
            :option-width="80"
            :option-height="20"
            :searchable="false"
            :allowEmpty="true"
            open-direction="bottom"
            @input="statusFilterChanged"
          >
            <template v-slot:singleLabel="props">
              <span class="option__title">{{ props.option.title }}</span>
              <img src="../assets/multiselect-arrow.png" class="arrow" />
            </template>
            <template v-slot:option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>

        <div class="search--buttons">
          <button
            class="button button-primary"
            id="add-new"
            v-on:click="addNew()"
          >
            <img src="@/assets/plus-circle.svg" alt="plus sign in circle" />
            Add
          </button>
        </div>
      </div>
      <div id="table-controls"></div>
      <table id="table">
        <thead id="company-header">
          <tr id="table-header">
            <th
              v-for="column in columns"
              v-bind:key="column.id"
              class="noselect"
              v-bind:class="column.id"
            >
              <span class="column-title" v-on:click="setSortBy(column.id)">{{
                column.title
              }}</span>
              <div
                class="arrow"
                v-bind:class="computedSortClass(column.id)"
                v-on:click="setSortBy(column.id)"
              ></div>
            </th>
            <th title="menu"></th>
          </tr>
        </thead>
        <tbody v-if="paginatedData.length > 0">
          <tr
            class="table-row"
            v-for="(company, index) in paginatedData"
            v-bind:key="company.id"
            v-bind:class="computedInactiveClass(company.status)"
          >
            <td>{{ company.name }}</td>
            <td>{{ company.userCount }}</td>
            <td>{{ company.documentCount }}</td>
            <td>{{ company.status }}</td>
            <td>{{ company.dateCreatedFormatted }}</td>
            <td
              v-on:click.prevent="
                $refs[`itemMenu${index}`][0].changeMenuStatus()
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="6"
                viewBox="0 0 26 6"
              >
                <g fill="#124E81" fill-rule="evenodd">
                  <circle cx="3" cy="3" r="3" />
                  <circle cx="13" cy="3" r="3" />
                  <circle cx="23" cy="3" r="3" />
                </g>
              </svg>
              <dropdown
                :key="index"
                :ref="`itemMenu${index}`"
                top="50px"
                left="-129px"
              >
                <p class="dropdown-item" v-on:click="viewCompany(company)">
                  View Company
                </p>
                <p class="dropdown-item" v-on:click="editCompany(company)">
                  Edit Company
                </p>
                <p class="dropdown-item" v-on:click="viewDocuments(company)">
                  View Documents
                </p>
                <p
                  class="dropdown-item"
                  v-on:click="makeCompanyInactive(company)"
                >
                  Make Inactive
                </p>
                <p
                  class="dropdown-item"
                  v-on:click="showConfirmDelete(company.id)"
                >
                  Delete Company
                </p>
              </dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <PaginationControls
        v-on:changePage="pageChange"
        :pageNumber="this.pagination.pageNumber"
        :pageMax="this.maxPage"
      />
    </div>
  </div>
</template>

<script>
import PaginationControls from '@/components/PaginationControls.vue'
import SearchInput from '@/components/SearchInput.vue'
import Dropdown from '@/components/DropDownMenu.vue'
import TrashIcon from '@/assets/trash.svg'
import BlockedIcon from '@/assets/block.png'
import CompanyIcon from '@/assets/company.svg'
import Multiselect from 'vue-multiselect'

export default {
  name: 'companyTable',
  components: {
    PaginationControls,
    SearchInput,
    Dropdown,
    Multiselect
  },
  data() {
    return {
      columns: [
        {
          id: 'name',
          title: 'Company Name'
        },
        {
          id: 'userCount',
          title: 'Users'
        },
        {
          id: 'documentCount',
          title: 'Documents'
        },
        {
          id: 'status',
          title: 'Status'
        },
        {
          id: 'dateCreatedFormatted',
          title: 'Creation Date'
        }
      ],
      status: {
        selected: {
          title: 'Status',
          id: 'blank'
        },
        options: [
          {
            title: 'Active',
            id: 'active'
          },
          {
            title: 'Inactive',
            id: 'inactive'
          }
        ]
      },
      sortBy: 'name',
      sortDirection: 'up',
      allCompanies: this.$companyStore.companies,
      pagination: {
        pageLimit: 20,
        pageNumber: 1
      },
      totalCount: this.$companyStore.totalCompanies,
      search: '',
      suggestions: []
    }
  },
  created() {
    this.refreshList()

    this.$events.$on('companies_refresh', (data) => {
      this.refreshList(data.companies, data.totalCompanies)
    })
  },
  computed: {
    maxPage: function () {
      return Math.ceil(this.totalCount / this.pagination.pageLimit)
    },
    computedInactiveClass: function () {
      return function (status) {
        return status === 'inactive' ? 'inactive' : ''
      }
    },
    computedSortClass: function () {
      return function (sortId) {
        if (this.sortBy === sortId) {
          return 'sorting ' + this.sortDirection
        } else {
          return ''
        }
      }
    },
    paginatedData: function () {
      let newData = []
      let startItem =
        this.pagination.pageLimit * (this.pagination.pageNumber - 1)
      let endItem = startItem + this.pagination.pageLimit

      // Sort
      this.$util.sortColumns(this.allCompanies, this.sortBy, this.sortDirection)

      // Return list
      for (startItem; startItem < endItem; startItem++) {
        if (this.allCompanies[startItem]) {
          newData.push(this.allCompanies[startItem])
        }
      }
      return newData
    }
  },
  watch: {
    $route() {
      this.adding = false
    }
  },
  methods: {
    addNew: function () {
      this.$events.$emit('showCompanyModal', {
        title: 'New Company',
        managed: false,
        mode: 'input',
        confirmText: 'Save',
        callback: async (confirmed, data) => {
          if (!confirmed) return
          this.$events.$emit('showLoading')
          console.log('New Company Data:')

          console.log(data)

          let companySuccess
          try {
            companySuccess = await this.$companyStore.add(
              data.inputValue,
              data.managed
            )
          } catch (err) {
            this.$events.$emit('error', err)
            companySuccess = false
          } finally {
            this.$events.$emit('hideLoading')

            if (companySuccess) {
              this.$notify({
                group: 'admin-actions',
                title: 'Created Company',
                text: `${data.inputValue} was successfully created`,
                data: {
                  iconPath: CompanyIcon
                }
              })
            }
          }
        }
      })
    },
    viewCompany: function (company) {
      setTimeout(async () => {
        try {
          await this.$session.updateSuperAdminCurrentCompany(company)
          this.$router.push(`/org/${company.id}/management/members`)
        } catch (err) {
          this.$events.$emit('error', err)
        }
      }, 250)
    },
    editCompany: function (company) {
      let submittedCallback = async (confirmed, data) => {
        if (!confirmed) return
        this.$events.$emit('showLoading')

        try {
          await this.$companyStore.updateById(company.id, {
            status: company.status,
            name: data.inputValue,
            managed: data.managed
          })

          this.$notify({
            group: 'admin-actions',
            title: 'Updated Company',
            text: `${data.inputValue} was successfully updated`,
            data: {
              iconPath: CompanyIcon
            }
          })
          await this.refreshList()
        } catch (err) {
          this.$events.$emit('hideLoading')
          let errMsg = 'Unknown Error'

          if (err.response && err.response.data && err.response.data.error) {
            errMsg = err.response.data.error
          } else if (err.message) {
            errMsg = err.message
          }

          setTimeout(() => {
            this.$events.$emit('showBasicModal', {
              title: 'Error',
              text: errMsg,
              mode: 'ok',
              confirmText: 'OK',
              callback: () => {
                setTimeout(() => {
                  this.$events.$emit('showBasicModal', {
                    title: 'Edit Company',
                    inputValue: company.name,
                    mode: 'input',
                    confirmText: 'Save',
                    callback: submittedCallback
                  })
                }, 100)
              }
            })
          }, 100)
        }
      }

      this.$events.$emit('showCompanyModal', {
        title: 'Edit Company',
        inputValue: company.name,
        managed: company.managed,
        mode: 'input',
        confirmText: 'Save',
        callback: submittedCallback
      })
    },
    setSortBy: function (sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === 'up') {
          this.sortDirection = 'down'
        } else {
          this.sortDirection = 'up'
        }
      } else {
        this.sortDirection = 'up'
        this.sortBy = sortBy
      }
    },
    viewDocuments: async function (company) {
      setTimeout(async () => {
        try {
          await this.$session.updateSuperAdminCurrentCompany(company)
          this.$router.push(`/org/${company.id}/documents/company`)
        } catch (err) {
          this.$events.$emit('error', err)
        }
      }, 250)
    },
    searchSubmitted: function () {
      this.refreshList()
    },
    companySearch: async function (val) {
      try {
        this.sortBy = 'name'
        if (val === '') {
          this.suggestions = []
          this.refreshList()
          return
        }
        const companies = await this.$companyStore.loadAll({ query: val }, true)

        if (companies.length === 0) {
          this.suggestions = [
            {
              id: 'none',
              name: 'No Results Found',
              points: 9999
            }
          ]

          return
        }

        this.suggestions = companies
      } catch (err) {
        console.log(err)
        this.$events.$emit('hideLoading')
      }
    },
    suggestion: function (suggestion) {
      if (suggestion.id === 'none') return
      this.suggestions = []
      this.search = suggestion.name
      this.allCompanies = [suggestion]
    },
    refreshList: async function (companies, count) {
      try {
        this.$events.$emit('showLoading')
        if (companies) {
          this.allCompanies = JSON.parse(JSON.stringify(companies))
          this.totalCount = count < 1 ? 1 : count

          for (let i = 0; i < this.allCompanies.length; i++) {
            this.allCompanies[i].dateCreatedFormatted = this.getFormattedDate(
              this.allCompanies[i].dateCreated
            )
            if (!this.allCompanies[i].userCount) {
              this.allCompanies[i].userCount = 0
            }
            if (!this.allCompanies[i].documentCount) {
              this.allCompanies[i].documentCount = 0
            }
          }
        } else {
          let status = this.status.selected.id
          if (status === 'blank') status = ''
          await this.$companyStore.loadAll(
            {
              query: this.search,
              status: status
            },
            false
          )
          this.pagination.pageNumber = 1
        }
        setTimeout(() => {
          this.$events.$emit('hideLoading', () => (this.suggestions = []))
        }, 500)
      } catch (err) {
        this.$events.$emit('error', err)
      }
    },
    getFormattedDate: function (timeStamp) {
      return this.$moment(timeStamp).format('MM/DD/YYYY')
    },
    pageChange: function (newPage) {
      this.pagination.pageNumber = newPage
    },
    makeCompanyInactive: function (company) {
      let companyId = company.id
      let cName = this.$companyStore.getCompanyNameSync(companyId)

      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: 'Block Confirmation',
        text: `Are you sure you want to make ${cName} inactive and suspend its subscription?`,
        secondaryText: 'This action cannot be undone.',
        callback: async (confirmed) => {
          try {
            if (!confirmed) return

            this.$events.$emit('showLoading')
            await this.$subscriptionStore.cancelSubscription(companyId)

            await this.$companyStore.updateById(companyId, {
              status: 'inactive',
              managed: company.managed
            })

            await this.refreshList()
            this.$events.$emit('hideLoading')

            this.$notify({
              group: 'admin-actions',
              title: 'Deactivated Company',
              text: `${cName} was successfully deactivated`,
              data: {
                iconPath: BlockedIcon
              }
            })
          } catch (err) {
            this.$events.$emit('error', err)

            return err
          }
        },
        cancelText: 'Cancel',
        confirmText: 'CONFIRM'
      })
    },
    showConfirmDelete: function (companyId) {
      let cName = this.$companyStore.getCompanyNameSync(companyId)

      this.$events.$emit('showBasicModal', {
        mode: 'confirm',
        title: 'Delete Confirmation',
        text: `Are you sure you want to delete ${cName}?`,
        callback: (resp) => {
          if (resp) {
            this.$events.$emit('showLoading')
            this.$companyStore
              .deleteCompanyById(companyId)
              .catch((err) => {
                this.$events.$emit('error', err)

                return err
              })
              .then((err) => {
                this.$events.$emit('hideLoading')

                if (!err) {
                  this.$notify({
                    group: 'admin-actions',
                    title: 'Deleted Company',
                    text: `${cName} was successfully deleted`,
                    data: {
                      iconPath: TrashIcon
                    }
                  })
                }
              })
          }
        },
        cancelText: 'Cancel',
        confirmText: 'Delete'
      })
    },
    statusFilterChanged: function (newVal) {
      if (!newVal) {
        this.status.selected = {
          title: 'Status',
          id: 'blank'
        }
      }
      this.refreshList()
    },
  }
}
</script>

<style lang="scss">
.company-tab {
  #company-header {
    //company Name
    th:nth-child(1) {
      width: 35%;
    }

    //Number of Users
    th:nth-child(2) {
      width: 17%;
    }

    //Numer of Docs
    th:nth-child(3) {
      width: 20%;
    }

    //Status
    th:nth-child(4) {
      width: 20%;
    }

    //Creation Date
    th:nth-child(5) {
      width: 18%;
    }

    //menu
    th:nth-child(6) {
      width: 7%;
    }

    th .column-title {
      padding-right: 5px;
      cursor: pointer;
    }

    th .arrow {
      width: 13px;
      height: 8px;
      background-image: url('../assets/sort-arrow.png');
      display: none;
      cursor: pointer;
    }

    th .arrow.sorting {
      display: inline-block;
    }

    th .arrow.sorting.up {
      transform: rotate(180deg);
    }
  }
  #add-new {
    margin-left: 24px;
  }

  .inactive {
    td {
      opacity: 0.2;
    }
    td:last-child {
      pointer-events: none;
    }
  }

  td:nth-child(4) {
    text-transform: capitalize;
  }
}
</style>
