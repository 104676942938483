<template>
  <div id="admin">
    <AdminTabs v-bind:tabs="tabs" v-bind:initialTab="$route.params.type" />
  </div>
</template>
<script>
import AdminTabs from '@/components/AdminTabs.vue'

export default {
  components: {
    AdminTabs
  },
  data() {
    return {
      tabs: [
        {
          title: 'Accounts',
          component: 'userTab',
          path: 'user'
        },
        {
          title: 'Companies',
          component: 'companyTab',
          path: 'company'
        }
      ]
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
#admin {
}
</style>
